<template>
  <!--Global Actions Template -->
  <n-grid
    :cols="header?.length > 0 ? header.length : 1"
    item-responsive
    responsive="screen"
    v-if="actions && actions != null"
    class="pb-4"
  >
    <n-gi :span="header?.length > 0 ? header.length : 1" style="text-align: end">
      <span>
        <n-dropdown trigger="click" :options="actions" placement="bottom-end">
          <n-button>{{ t("Components.Table.actionLabel") }}</n-button>
        </n-dropdown>
      </span>
    </n-gi>
  </n-grid>
  <!-- Filter Template-->
  <n-collapse class="pb-2" v-if="showFilters">
    <template #arrow> </template>
    <template #header-extra>
      {{ t("Components.Table.filterLabel") }}
      <n-icon>
        <FilterIcon />
      </n-icon>
    </template>
    <n-collapse-item>
      <n-form @submit.prevent :model="filter" ref="formRef">
        <n-space
          :justify="
            fieldForRangeOfDates && fieldsFilterables.length > 0 ? 'space-between' : 'end'
          "
        >
          <n-form-item
            @keyup.enter="filterAction"
            label="Search"
            path="filterString"
            v-if="fieldsFilterables.length > 0"
          >
            <n-input-group>
              <n-input
                :style="{ width: '100%', textAlign: 'left' }"
                v-model:value="filter.filterString"
                :disabled="filterInProgress"
                :placeholder="null"
              >
              </n-input>

              <n-button
                type="primary"
                ghost
                @click="filterAction"
                :disabled="filterInProgress"
              >
                <n-icon> <SearchIcon /> </n-icon>
              </n-button>
            </n-input-group>
          </n-form-item>
          <n-form-item label="Filter by dates" v-if="fieldForRangeOfDates">
            <n-date-picker v-model:value="filter.range" type="daterange" clearable />
          </n-form-item>
        </n-space>
      </n-form> </n-collapse-item
  ></n-collapse>

  <!--Table Template -->
  <n-card content-style="text-align: center;">
    <!--Header Template -->
    <n-grid
      :cols="itemAction ? header.length + 1 : header.length"
      class="header"
      item-responsive
      responsive="screen"
    >
      <n-gi
        :class="
          index === header.length - 1 && itemAction === null ? 'grid no-border' : 'grid'
        "
        v-for="(val, index) of header"
        :key="index"
      >
        <div>{{ val }}</div>
      </n-gi>

      <n-gi v-if="itemAction && itemAction != null" class="grid no-border">
        <div></div>
      </n-gi>
    </n-grid>
    <!--Content Template -->
    <n-grid
      :cols="itemAction ? header.length + 1 : header.length"
      class="body"
      item-responsive
      responsive="screen"
      v-for="item of itemsToShow"
      :key="item"
    >
      <!--Data Template -->
      <n-gi
        :class="
          index === header.length - 1 && itemAction === null ? 'grid no-border' : 'grid'
        "
        v-for="(val, index) of header"
        :key="index"
      >
        <div>
          <n-ellipsis>{{ item[val] }}</n-ellipsis>
        </div>
      </n-gi>
      <!--Item Actions Template -->
      <n-gi v-if="itemAction && itemAction != null" class="grid no-border">
        <div v-if="itemAction(item)?.length > 0">
          <n-dropdown
            size="small"
            :options="itemAction(item)"
            trigger="click"
            placement="bottom-end"
            :bordered="false"
          >
            <n-button text>
              <template #icon>
                <n-icon class="pt-1">
                  <ItemMenuIcon />
                </n-icon> </template
            ></n-button>
          </n-dropdown>
        </div>
      </n-gi>
    </n-grid>
    <!--Data not found Template -->
    <n-grid
      cols="1"
      class="body"
      item-responsive
      responsive="screen"
      v-if="data?.length === 0"
    >
      <n-gi class="grid no-border" :span="1">
        <div>
          <span>{{ t("Components.Table.itemsNoFound") }}</span>
        </div>
      </n-gi>
    </n-grid>
  </n-card>
  <!--Pagination -->
  <n-space justify="center"
    ><n-pagination
      class="pt-4"
      v-model:page="currentPage"
      :page-count="pageCount"
      v-if="pageCount > 1"
    />
  </n-space>
</template>
<script>
import { defineComponent, ref, h, computed } from "vue";
import { DotsVertical as ItemMenuIcon, Filter as FilterIcon } from "@vicons/tabler";
import {
  NIcon,
  NGrid,
  NGi,
  NDropdown,
  NButton,
  NCard,
  NEllipsis,
  NSpace,
  NPagination,
  NForm,
  NFormItem,
  NInputGroup,
  NInput,
  NDatePicker,
  NCollapse,
  NCollapseItem,
} from "naive-ui";
import { SearchOutline as SearchIcon } from "@vicons/ionicons5";
import { useI18n } from "vue-i18n";
const renderIcon = (icon) => {
  return () => {
    return h(NIcon, null, {
      default: () => h(icon),
    });
  };
};
export default defineComponent({
  name: "Table",
  components: {
    ItemMenuIcon,
    FilterIcon,
    NIcon,
    NGrid,
    NGi,
    NButton,
    NCard,
    NEllipsis,
    NDropdown,
    NSpace,
    NPagination,
    SearchIcon,
    NForm,
    NFormItem,
    NInputGroup,
    NInput,
    NDatePicker,
    NCollapse,
    NCollapseItem,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => {
        [];
      },
    },
    globalActions: { type: Object, required: false, default: null },
    itemsActions: { type: Function, required: false, default: null },
    fieldsToHide: {
      type: Array,
      required: false,
      default: () => [],
    },
    fieldsFilterables: {
      type: Array,
      required: false,
      default: () => [],
    },
    fieldForRangeOfDates: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const currentPageRef = ref(1);
    const itemsPerPageRef = ref(5);
    const filterRef = ref({
      filterString: null,
    });
    const filterInProgressRef = ref(false);

    return {
      t,
      header: computed(() => {
        return props.data.length > 0
          ? Object.keys(props.data[0]).filter(
              (field) => !props.fieldsToHide?.includes(field)
            )
          : [];
      }),
      currentPage: currentPageRef,
      pageCount: computed(() => Math.ceil(props.data.length / itemsPerPageRef.value)),
      itemsToShow: computed(() =>
        props.data.slice(
          currentPageRef.value * itemsPerPageRef.value - itemsPerPageRef.value,
          currentPageRef.value * itemsPerPageRef.value
        )
      ),
      actions: computed(() => {
        return props.globalActions?.map((action) => {
          return {
            label: action.label,
            key: action.key,
            icon: action.icon ? renderIcon(action.icon) : null,
            disabled: action.disabled,
            props: action.props,
          };
        });
      }),
      itemAction: computed(() => {
        if (props.itemsActions != null) {
          return (item) => {
            let actions = props.itemsActions(item);
            return actions?.map((action) => {
              return {
                label: action.label,
                key: action.key,
                icon: action.icon ? renderIcon(action.icon) : null,
                disabled: action.disabled,
                props: action.props,
              };
            });
          };
        } else {
          return null;
        }
      }),
      filter: filterRef,
      filterInProgress: filterInProgressRef,
      filterAction: () => {
        console.log("to implement");
      },
      showFilters: computed(() => {
        return props.fieldsFilterables?.length > 0 || props.fieldForRangeOfDates;
      }),
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(.n-card__content) {
  padding: unset;
  padding-top: unset !important;
}
.header {
  font-weight: 500;
  font-size: 14px;
  //background-color: rgba(0, 128, 0, 0.12);
  background-color: #0b40a8 !important;
  color: #ffffff !important;
  @media screen and (max-width: 799px) {
    font-size: 12px;
  }
}
.body {
  align-content: center;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: rgb(239, 239, 245);
}

.grid {
  height: 30px;
  padding-top: 5px;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: rgb(219, 219, 219);
}
.no-border {
  border-right-style: unset;
}

.pt-0 {
  padding-top: 0px;
}
</style>
